import React, { useState } from "react";
import { Redirect } from "react-router";
import "./home.css";

export function Home(props) {
  const [redirectState, setRedirectState] = useState("");

  if (redirectState === "EvolveConf") {
    window.location.replace("https://www.evolveconf.se/#/start");
    return null;
  }
  if (redirectState === "3rdBirthday") {
    return <Redirect push to="/events/3rdbirthday" />;
  }

  return (
    <div className="view">
      <div className="header">
        <img height={50} src="evolve_black.png" alt="Evolve"></img>
      </div>
      <div className="wrapper">
        <ul>
          <li onClick={() => setRedirectState("EvolveConf")}>
            <div className="row">
              <div className="eventDateContainer">
                <span className="eventMonth">Feb</span>
                <span className="eventDay">28</span>
              </div>
              <div className="infoContainer">
                <h2>Evolve Conf</h2>
                <span>Ignite your passion!</span>
              </div>
            </div>
          </li>
          <li onClick={() => setRedirectState("3rdBirthday")}>
            <div className="row">
              <div className="eventDateContainer">
                <span className="eventMonth">Apr</span>
                <span className="eventDay">03</span>
              </div>
              <div className="infoContainer">
                <h2>Evolve's 3rd birthday</h2>
                <span>
                  Don't miss the party of the year &#9829; RSVP today!
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="footer">
        <img height={70} src="evolve_white.png" alt="Evolve"></img>
      </div>
    </div>
  );
}
