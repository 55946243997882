import React from "react";
import "./modal.css";
import { PinkButton } from "../";

export function Modal(props) {
  if (!props.show) {
    return null;
  }

  //  const className = props.show ? ""

  return (
    <div className="modal-window open-modal">
      <div className="container">
        <div className="iframe-container">
          <iframe
            title="RSVP"
            class="airtable-embed"
            src="https://airtable.com/embed/shrbRw0XDTgVDAMCk?backgroundColor=yellow"
            frameBorder={0}
            style={{ width: "100%", height: "60vh" }}
          >
            Browser unable to load...
          </iframe>
        </div>
        <PinkButton onClick={props.onClose}>Close</PinkButton>
      </div>
    </div>
  );
}
