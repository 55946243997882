import React from "react";
import "./invitation.css";

export function Completed(props) {
  return (
    <div className="completedPage">
      <div className="completedContainer">
        <i className="fa fa-check-circle icon"></i>
        <h3>Yay! Registration completed!</h3>
        <img height={200} src="party.gif" alt="Party!"></img>
        <p>
          We look forward to seeing you at the party on{" "}
          <strong>April 3rd</strong> at <strong>Götgatan 15</strong>.
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1240.3261334447056!2d11.970512951843553!3d57.705442634950835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff364687b0fed%3A0x99628aa70d38db98!2sG%C3%B6tgatan%2015%2C%20411%2005%20G%C3%B6teborg!5e0!3m2!1sen!2sse!4v1582108422652!5m2!1sen!2sse"
          title="map"
          width="600"
          height="250"
          frameborder="0"
          style={{ border: "0" }}
          className="map"
          allowfullscreen=""
        ></iframe>
      </div>
      <div className="footer">
        <img height={70} src="evolve_white.png" alt="Evolve"></img>
      </div>
    </div>
  );
}
