import React from "react";
import "./eventinfobox.css";

export function EventInfoBox(props) {
  return (
    <div className="eventInfoContainer">
      <div className="filler"></div>
      <div className="dateContainer">
        <span className="month">{props.month}</span>
        <span className="day">{props.day}</span>
      </div>
      <div className="filler"></div>
      <div className="informationContainer">
        <div className="row">
          <div className="column">Time:</div>
          <div className="column">{props.time}</div>
        </div>
        <div className="row">
          <div className="column">Place:</div>
          <div className="column">{props.place}</div>
        </div>
      </div>
      <div className="filler"></div>
    </div>
  );
}
