import React, { useState } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import "./invitation.css";
import Confetti from "react-confetti";
import { EventInfoBox, PinkButton, Modal } from "../../components";

export function Invitation(props) {
  const [showModal, setShowModal] = useState(false);
  const { width, height } = useWindowSize();

  return (
    <div>
      <Modal show={showModal} onClose={() => setShowModal(false)} />
      <Confetti width={width} height={height} numberOfPieces={40} />
      <div className="header">
        <img height={50} src="/evolve_black.png" alt="Evolve"></img>
      </div>
      <div className="wrapper">
        <div className="invitation">
          <h1>
            We're getting
            <br />
            older and
            <br />
            you're invited!
          </h1>
          <EventInfoBox
            month="apr"
            day="03"
            time="18.00 - LATE"
            place={
              <span>
                EVOLVE'S HQ
                <br />
                Götgatan 15
                <br />
                411 05 Göteborg
              </span>
            }
          />
          <div className="eventDescriptionContainer">
            <h3>Do you know why you don't wanna miss this?</h3>
            <h2>Because EVOLVE is throwing the party</h2>
            <p>
              Three years have passed and a lot has been going on inside the
              walls of Evolve.
            </p>
            <p>
              It's time to celebrate, not only our birthday - it's time to
              celebrate all the incredible people that we met through the years!
            </p>
            <p>
              That's why we're inviting you to come party with us for one night,
              and one night only.
            </p>
            <p>
              There will be surprises, there will be snacks and beverage and
              there will definitely be a night of joy!
            </p>
            <div className="signup">
              <span style={{ marginRight: "25px" }}>
                Be sure to register NOW
              </span>
              <PinkButton
                iconClass="heart"
                onClick={e => setShowModal(!showModal)}
              >
                Register
              </PinkButton>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <img height={70} src="/evolve_white.png" alt="Evolve"></img>
      </div>
    </div>
  );
}
