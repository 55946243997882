import React from "react";
import "./pinkbutton.css";

export function PinkButton(props) {
  const buttonClass = `button hvr-grow-rotate ${props.iconClass}`;
  return (
    <button className={buttonClass} onClick={props.onClick}>
      {props.children}
    </button>
  );
}
